import React, { useEffect, useState, useRef } from 'react';
import { useRoutes, navigate } from 'hookrouter';
import { Image, Button, DropdownButton, Dropdown } from 'react-bootstrap';
import { TweenLite, Sine, Elastic, Power1, Power2 } from 'gsap';
import firebase from 'firebase';
import LeadershipCompass from '../../app_suite/LeadershipCompass/LeadershipCompass';
//import CreativeGroup from '../../app_suite/CreativeGroup/CreativeGroup';

import None from '../../app_suite/None/None';
import { useUrlParams } from '../../app_suite/common_old';
import campbellCompassIcon from './images/cc-app-icon-square.png';
import creativeGroupIcon from './images/cg-app-icon-square.png';
import './Home.css';
//import { Portfolios } from '../../app_suite/CreativeGroup/forms/Admin/api_components';

export default function Home(props) {
  //console.log('PROPS: ', props);
  let segment = useUrlParams();
//console.log('segment: ', segment);
  const match = useRoutes({}); // Uses HookRouter for establishing an address bar listener and for its navigate() function
  return (
    <div className="Home-Enter">
    <div style={{display: 'flex', justifyContent: 'center'}}><div className="Home-Background"></div></div>
      <HomeNavbar user={props.user}/>
      {/*<BuildInfo />*/}
      <Subapps show={segment[1]} user={props.user} />
    </div>
  )
}

function BuildInfo(){
  return(
    <div style={{opacity: .4, position: 'fixed', top: 100, left: 100, xIndex: 1000, color: '#ffffff'}}>
      <p>{process.env.REACT_APP_TITLE}</p>
      <p>Asana team ID: {process.env.REACT_APP_ASANA_TEAM_ID}</p>
    </div>
  );
}


function Subapps(props){
  let [iconCoordinates, setIconCoordinates] = useState('');
  return(
    <div>
      <div className="Home-Icons-Wrapper">

        <div  className="Home-Icons-Row">
          <Icon title="Creative Group" url="/creative_group" icon={creativeGroupIcon} iconImageSize="60" iconCoords={setIconCoordinates}>
          </Icon>
          <Icon title="Campbell Compass" url="/campbell_compass" icon={campbellCompassIcon} iconImageSize="61" iconCoords={setIconCoordinates}> 
          </Icon>    
          <Icon hidden={true} title="">
          </Icon>     
          <Icon hidden={true} title="" >
          </Icon>
        </div>
      </div>
      <LeadershipCompass show={props.show}  user={props.user} iconRef={iconCoordinates} />
      {/*<CreativeGroup show={props.show} user={props.user} iconRef={iconCoordinates} />*/} 
      <None show={props.show} click={props.getClick} iconRef={iconCoordinates} />
    </div>
  )
}

function Icon(props){
  let refIcon = useRef(null);
  function iconClicked(e){
    //TweenLite.to(refIcon.current, .15, {transform: 'scale(2, 2)', opacity: .5});
    //var rect = refIcon.current.getBoundingClientRect();
    props.iconCoords(refIcon);
    navigate(props.url);
  }
  return(
    <div  className="Home-Icon-Wrapper">
      {props.children}
      <div ref={refIcon} className={props.hidden ? 'Home-Icon-hidden' : 'Home-Icon'} onClick={(e)=>iconClicked(e)} > 
      <Image src={props.icon} style={{width: props.iconImageSize + 'px' }} /> 
      </div>
      <div className="Home-Icon-Title">{props.title}</div>
    </div>
  );
}

function HomeNavbar(props) {
  return (
    <div className="Home-Navbar">
      <div className="Home-Navbar-Wrapper">
  {/*<div className="Home-Navbar-logo"><Image src={csiLogo} height="27px"/>  </div>*/}
        <div>
        <Dropdown>
    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <svg style={{height: 17, width: 17, marginTop: 10, color: 'rgba(0,0,0,0.6)'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 -144 1008 1008">
          <path
            fill="currentColor"
            d="M510 124c31 0 57-11 79-33s33-48 33-79-11-57-33-79-48-33-79-33-57 11-79 33-33 48-33 79 11 57 33 79 48 33 79 33zm-3 481l3 3c-31 0-57 11-79 33s-33 45-33 70 5 46 15 63 23 30 40 40 38 15 63 15 48-11 70-33 33-48 33-79-11-57-33-79-48-33-79-33zm-59-149l3 3c17 10 38 15 63 15s48-11 70-33 33-48 33-79-11-57-33-79-48-33-79-33-57 11-79 33-33 45-33 70 5 46 15 63 23 30 40 40z"
          ></path>
        </svg>
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <Dropdown.Item onClick={()=>signOut()}>Sign Out</Dropdown.Item>
      {/*<Dropdown.Item eventKey="2">Blue</Dropdown.Item>*/}
    </Dropdown.Menu>
  </Dropdown>
        </div>
        {/*<DropdownButton title="Options" size="sm">  </DropdownButton> */}
        <div></div>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <div className="Home-Navbar-Title"><span style={{opacity: .55}}>cs</span>AppSuite</div>
          {/*<Image id="dropdown-basic-button" style={{height: 35, opacity: .8, margin: '0px -10px 0 8px'}} src="https://firebasestorage.googleapis.com/v0/b/csi-app-farm.appspot.com/o/tree-1.png?alt=media&token=38988fe9-6f0c-4e6f-9e74-c2eb59895779" />*/}
        </div>
          {/*<Dropdown.Item onClick={()=> updateProfile(props.user) } href="#/action-1">Clear displayName</Dropdown.Item>
  <Dropdown.Item onClick={() => signOut()} href="#/action-1">Sign Out</Dropdown.Item>*/}


      </div>
    </div>
  )
}

// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));


function signOut() {
  firebase.auth().signOut()
    .then(function(){
      console.log('you are signed out!');
    }
  );
}

function updateProfile(user){
  console.log('inside updateProfile()');
  user.updateProfile({displayName: null })
  .then(() => {
    console.log('displayName cleared: ', user.displayName);
    window.location.replace('/');
  })
  .catch(()=>{
    console.log('You screwed up');
  })

}

