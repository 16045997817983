import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyDiLM4bh9rCi0GVETPLAk_8cbOHDVM4lrk",
  authDomain: "apoc-books-bed22.firebaseapp.com",
  databaseURL: "https://apoc-books-bed22.firebaseio.com",
  projectId: "apoc-books-bed22",
  storageBucket: "apoc-books-bed22.appspot.com",
  messagingSenderId: "418583593479",
  appId: "1:418583593479:web:0011260bb74a68ab7060a2",
  measurementId: "G-JQTXPW3H9K"
};

firebase.initializeApp(firebaseConfig);
