import React, { useEffect, useState, useRef} from 'react';
import { navigate } from 'hookrouter';
import { Button, Image } from 'react-bootstrap';
import { enterApp, exitApp, useUrlParams } from '../common.js';
import Introduction from './Introduction/Introduction';
import Assessment from './Assessment/Assessment';
import  homeMugShot from './images/home-mugshot.png';
//import Compass from '../../components/Compass/Compass';
//import { CCButton, CCButtonCircle } from '../../components/CompassComponents/CompassComponents';
import { HomeIcon, AssessNavIcon, PlanNavIcon, ExecuteNavIcon, BackIcon, BackIconAlt } from '../../svg/svg_icons.js';
import {useSpring, animated} from 'react-spring'
import firebase from 'firebase';
import '../../firebase-init.js'
import './LeadershipCompass.css';

let db = firebase.firestore();

export default function LeadershipCompass(props) {
  const pageRef = useRef(null);
  // const refContent = useRef(null);
  // let [userAccount, setUserAccount] = useState(null);
  // let [compassPosition, setCompassPosition] = useState(0);

  const thisApp = 'campbell_compass';
  let segment = useUrlParams();

  let [prev, setPrev] = useState('');
  let [render, setRender] = useState(false);
  let [animate, setAnimate] = useState(false);
  let [pageMode, setPageMode] = useState({});

  useEffect(()=> {
 
      console.log('width: ', window.innerWidth);

    if( props.show === thisApp && prev != thisApp  ){
      //enterApp(pageRef, setRender, props.iconRef);
      setRender(true);
      setAnimate(true);
      setPrev(thisApp);
    }
    if( props.show != thisApp && prev === thisApp ){
      //exitApp(pageRef, setRender, props.iconRef);
      setAnimate(false);
      setTimeout(()=> { setRender(false) }, 600);
      setPrev(props.show);
    }
  });

  const springProps = useSpring({
    opacity: animate ? 1 : 0,
    position: 'absolute', 
    top: 0, 
    right: 0, 
    bottom: 0, 
    left: 0, 
    backdropFilter: 'blur(30px)',
    backgroundColor: 'transparent'
  })

  const appMargins = {
    position: 'absolute', 
    ...pageMode
  }
//
  return(
    <React.Fragment>
    {render ?
      <div>
        <animated.div style={springProps}>
          <div ref={pageRef} className="LC-wrapper" style={appMargins}>
            <Button style={{position: 'absolute', top: 0, left: 0}} onClick={()=> window.history.back() }>x</Button>
            <iframe src="https://jeff-scratchpad-staging.web.app/" width="400" height="600" frameBorder="0" style={{borderRadius: 30, backgroundColor: 'transparent'}}>
            this is the default content of the iframe
            </iframe>

            <Assessment show={segment[2]} />
          </div>
        </animated.div>
      </div>
      
      : ''
    }
      
    </React.Fragment>
  )
}