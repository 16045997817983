import React, { useEffect, useState, useRef} from 'react';
import { useRoutes, nextPath, navigate, useControlledInterceptor } from 'hookrouter';
import { Image, Button, DropdownButton, Dropdown } from 'react-bootstrap';
import firebase from 'firebase';
import {useSpring, animated} from 'react-spring';
import { TweenLite } from 'gsap';
import './None.css';

export default function None(props) {

  return (
    <div className="None" >
      This is the None App
    </div>
  )
}