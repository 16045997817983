import React, { useState, useEffect, Fragment } from 'react';
import { Container, Button, Form, Modal, Alert, InputGroup, FormControl } from 'react-bootstrap';
import {useSpring, animated} from 'react-spring';
import firebase from 'firebase';
import './CreateProfile.css';

function CreateProfile(props) {
    let userObject = {};
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [reauthVisible, setReauthVisible] = useState(false);
    const [reauthAlertVariant, setReauthAlertVariant] = useState('info');
    const [alert, setAlert] = useState('');
    const [transition, setTransition] = useState(0);

    // Page Transition on enter and exit
    let transitionSpring = null;
    if(transition){
      transitionSpring = {config: { tension: 450, friction: 25 }, 
        opacity: 1, transform: 'scale(1)'}
    }else{
      transitionSpring = {opacity: 0, transform: 'scale(0.4)' }
    }
    const styleProps = useSpring(transitionSpring);
    useEffect(()=> { setTransition(1) },[]); // Toggle enter / exit

    const firstNameHandler = (e) => { setFirstName(e.target.value) }
    const lastNameHandler = (e) => { setLastName(e.target.value) }
    const passwordHandler = (e) => { setPassword(e.target.value) }
    const oldPasswordHandler = (e) => { setOldPassword(e.target.value) }
  
    function createUserDatabaseRecord(firstName, lastName){
      let user = firebase.auth().currentUser;
      console.log('the user.uid: ', user);
      let db = firebase.firestore();
      userObject = { 
        uid: user.uid, 
        firstName: firstName, 
        lastName: lastName, 
        email: user.email, 
        //phone: user.phoneNumber,
        photoUrl: user.photoURL,
        bio: "",
        groups: ['1'] // '0' is the group ID for superadmin.
                    // '1' is the group ID for a "registered" user. 
      };    
      db.collection("users").doc(user.uid).set(userObject)
      .then(function() {
          console.log("Document successfully written!");
          window.location.replace('/');
      })
      .catch(function(error) {
          console.error("Error writing document: ", error);
      });
    }

    function updateUserDisplayName(){
      let user = firebase.auth().currentUser;
      user.updateProfile({displayName: firstName + " " + lastName })
      .then(() => {
        createUserDatabaseRecord(firstName, lastName);
      })
      .catch((error)=>{
        console.log('You screwed up', error.code);
      });
    }


    function updateUserAccount(){
      console.log('inside updateUserAccount()');
      let user = firebase.auth().currentUser;
      user.updatePassword(password)
      .then(()=> {
        console.log('Password updated successfully');
        // Now that the user.password has been updated, let's updated the user.displayName property
        updateUserDisplayName();      
      })
      .catch((error)=> {
        console.log('You screwed up your password update: ', error.code);
        if(error.code === 'auth/requires-recent-login' ){
          console.log('You you took too damn much time. Now you have to enter your old password again.');
          var user = firebase.auth().currentUser;
          // Prompt the user to re-provide their password b y opening the reauth Alert
          setReauthVisible(true);
          var credential = firebase.auth.EmailAuthProvider.credential(
            user.email,
            oldPassword
          );        
          user.reauthenticateWithCredential(credential).then(function() {
            console.log('YOU RE-AUTHENTICATED');
            setReauthAlertVariant('success');
            setReauthVisible(true);
          }).catch(function(error) {
            console.log('A re-authentication error happened: ', error)
          });
        }
      });
    }

    function reAuthenticate() {
      setReauthVisible(true); // This is a reauth, so make the reauth warning visible
      updateUserAccount();
    }

    const saveButton = 
      firstName && lastName && password && (password.length > 5) && !reauthVisible ? 
        <Button size="lg" variant="primary" onClick={() => updateUserAccount()} > {/* // This is NOT a reauth, so make the button clickable */}
          Save Changes
        </Button>
      :
        <Button size="lg" disabled variant="primary" onClick={() => updateUserAccount()}>{/* // This is a reauth, so disable the button */}
          Save Changes
        </Button>
      ;

    const reauthAlertContent = 
      reauthAlertVariant === 'success' ?
        <Fragment>
          <p>SUCCESS!</p>
          <p> Close this alert box, then click the Save Changes button below.</p>
        </Fragment>
      :
        <Fragment>
          <p>Too much time has passed. You must re-enter the temporary password you were issued, then click "Submit."</p>
          <Form.Group style={{width: '100%', display: 'flex'}}>              
            <Form.Control  size="lg" onChange={ oldPasswordHandler } type="password" placeholder="" />
            <Button style={{float: 'right'}} onClick={() => reAuthenticate() }>Submit</Button>
          </Form.Group>
        </Fragment>
      ;


    return(
      <React.Fragment>
        <div className="CreateProfile-background"></div>
        <Container className="CreateProfile">

            <Form className="CreateProfile-Form">
              <h3 style={{marginBottom: 25}}>Create Account</h3>
              <Form.Group style={{width: '100%'}}>
                <Form.Control size="lg"  onChange={ firstNameHandler } type="text" placeholder="First name" />
              </Form.Group>
              <Form.Group style={{width: '100%'}}>
                <Form.Control  size="lg" onChange={ lastNameHandler } type="text" placeholder="Last Name" />
              </Form.Group>
              <Form.Group style={{width: '100%'}}>    
              <Form.Label style={{marginBottom: 5}}>Create your own password below</Form.Label>              
                <InputGroup className="mb-3">
                  <FormControl style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                  size="lg" onChange={ passwordHandler } type={passwordVisible ? "text" : "password"} placeholder="New Password"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text size="lg" variant="outline-primary" onClick={()=> setPasswordVisible(!passwordVisible)}  >{passwordVisible ? 'Hide' : 'Show'}</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>

                </Form.Group>
                <Alert show={reauthVisible} style={{width: '100%'}} variant={reauthAlertVariant} onClose={()=> setReauthVisible(false) } dismissible >
                  {reauthAlertContent}
                </Alert>
              <div style={{border: '1px soild black'}} className="CreateProfile-alert-visible">{alert}</div>
              {saveButton}
              <Button style={{marginTop: 50}} variant="primary-outline" onClick={()=> firebase.auth().signOut() }>
                CANCEL
              </Button>
            </Form>

        </Container>
      </React.Fragment>
    )
  }

  export default CreateProfile;