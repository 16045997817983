import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Alert, Modal } from 'react-bootstrap';
import {useSpring, animated} from 'react-spring';
import { useKey } from '../../app_suite/common.js'
import firebase from 'firebase';
import './Signin.css';

function Signin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [alert, setAlert] = useState('');
    const [registered, setRegistered] = useState(false);
    const [resetPw, setResetPw] = useState(false);
    //const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
    const wrapperRef = useRef(null);
    const inputRef = useRef(null);

    const [transition, setTransition] = useState(0);
    let transitionSpring = null;
    // Transition on enter and exit
    if(transition){
      transitionSpring = {config: { tension: 450, friction: 25 }, 
        opacity: 1, transform: 'scale(1)'}
    }else{
      transitionSpring = {opacity: 0, transform: 'scale(0.4)' }
    }
    const styleProps = useSpring(transitionSpring);
    useEffect(()=> { setTransition(1) },[]); // Transition enter / exit

    const enter = useKey('enter');
    //console.log('KEY PRESSED IS: ', enter);
    // If enter key pressed then signin user
    if (enter){
      if(resetPw){
        console.log('Enter pressed in reset password');
      }else{
        console.log('Enter pressed in signin user');
        signinUser();
      }
    } 
  
    const emailFieldHandler = (e) => {
      setEmail(e.target.value);
      setAlert('');
    }
    
    const passwordFieldHandler = (e) => {
      setPassword(e.target.value);
      setAlert('');
    }

    function resetPassword(emailFieldRef){
      let email = emailFieldRef.current.value;
      console.log('emailFieldRef: ', emailFieldRef.current.value);
      firebase.auth().sendPasswordResetEmail(email).then(function() {
        // Email sent.
        console.log('Password reset email has been sent.');
        setResetPw('');
      }).catch(function(error) {
        // An error happened.
        console.log('There was an error in sending the password reset email: ', error );
        setAlert(error.message);
      });
    }
  

    function signinUser() {
      firebase.auth().signInWithEmailAndPassword(email, password)
        .then( (result) => {
          let user = firebase.auth().currentUser;
          console.log('You signed in', user.email);
          if(user.displayName) {
            setRegistered(true);
          }
          setRegistered(false);
          setEmail('');
          setPassword('');
        })
        .catch(function(error) {
        console.log('code: ', error.code);
        console.log('message', error.message);
        setAlert(error.message);
      });
    }

    return(
      
        <React.Fragment>
        <div className="Signin-background">
          <div ref={wrapperRef} className="Signin">
            <animated.div style={styleProps}>
              <Form className="Signin-form" >
                  <Form.Group >
                    <Form.Control style={{marginBottom: 0}}  onChange={ emailFieldHandler } type="email" value={email} placeholder="Enter email" />
                  </Form.Group>
                  <Form.Group >
                    <Form.Control onChange={ passwordFieldHandler } type="password" value={password} placeholder="Password" />
                  </Form.Group>
                                
                  <Button style={{margin: '-5px 0 20px 0'}} variant="outline-secondary btn-block" onClick={() => signinUser()}>
                    Sign In
                  </Button> 
                  <Button className="btn-link" size="sm" variant="link" onClick={()=> setResetPw(true) }>
                    Reset Password
                  </Button>

              </Form>
            </animated.div>
          </div>

            {/* Sign in Modal */}
            <Modal show={alert ? true : false} onHide={()=>setAlert('')} size="sm" >
              <Modal.Header closeButton>
                <Modal.Title>Sign in error</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p style={{textAlign: 'left', color: 'red'}}>
                  {alert}
                </p>
              </Modal.Body>
            </Modal>

            {/* Reset password Modal */}
            <Modal show={resetPw ? true : false} onHide={()=>setResetPw('')} size="sm" >
              <Modal.Header closeButton>
                <Modal.Title>Reset password</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  Submit your email address to receive password reset instructions.
                </p>
                <Form.Group>
                  <Form.Control ref={inputRef} size="lg" type="email" placeholder="Enter email here" />
                </Form.Group>
                <Form.Group>
                  <Button size="lg" className="btn-block Submit-button" onClick={()=> resetPassword(inputRef)}>Submit</Button>
                </Form.Group>
              </Modal.Body>
            </Modal>

          </div>
        </React.Fragment>
    )
  }

  export default Signin;