import React, { useEffect, useRef } from 'react';
import { TweenLite, Sine, Elastic, Power1, Power2, Power3, Power4 } from 'gsap';
 
export function slideInFromBottom(props, myRef, parent){
  //console.log('Inside slideInFromBottom = ', parent);
  TweenLite.fromTo(myRef.current, .3, 
    {
      display: 'none',
      transform: 'translateY(100vh)',
      opacity: 1,
      width: '100vw',
      height: '200vh',
    },
    {
      display: 'block',
      transform: 'translateY(0)',
      top: 0,
      left: 0,
      opacity: 1,
      borderRadius: 0,
      ease: Power2.easeOut
      //ease: Elastic.easeOut.config(.3, .4)
    }
  );
} 

export function slideOutToBottom(props, myRef, parent){
  //console.log('Inside slideOutToBottom = ', parent);
  TweenLite.fromTo(myRef.current, .3, 
    {
      display: 'block',
      transform: 'translateY(0)',
      top: 0,
      left: 0,
      opacity: 1,
      borderRadius: 0,
    },
    {
      display: 'none',
      transform: 'translateY(100vh)',
      opacity: 1,
      width: '100vw',
      height: '100vh',
      ease: Power1.easeIn
      //ease: Elastic.easeInOut.config(.9, .8)
    },
  );
} 

export function enterApp(props, myRef){
  TweenLite.fromTo(myRef.current, 0.20, 
    {
      display: 'none',
      transform: 'scale(0,0)',
      //top: (-window.innerHeight / 2) + props.click.y,
      //left: (-window.innerWidth / 2) + props.click.x,
      opacity: 0,
      width: '100vw',
      height: '100vh',
      //marginTop: 300,
      borderRadius: 0,
      ease: Sine.easeOut
    },
    {
      display: 'block',
      transform: 'scale(1,1)',
      top: 0,
      left: 0,
      opacity: 1,
      borderRadius: 0,
      ease: Sine.easeOut
    }
  );
}
  
export function exitApp(props, myRef){
  TweenLite.fromTo(myRef.current, 0.30, 
    {
      display: 'block',
      transform: 'scale(1,1)',
      top: 0,
      left: 0,
      opacity: 1,
      ease: Sine.easeOut
    },
    {
      transform: 'scale(0,0)',
      //top: (-window.innerHeight / 2) + props.click.y,
      //left: (-window.innerWidth / 2) + props.click.x,
      opacity: 0,
      width: '100vw',
      height: '100vh',
      display: 'none',
      borderRadius: 0,
      ease: Sine.easeOut
    }
  );
}

/******** MY CUSTOM HOOKS *******/

// Returns an array that holds the URL paramaters, after the domain name */
export function useUrlParams(){
  let p = window.location.pathname.split('/');
  return p;
}

// A Hook for adding event listeners. An article about his custom hook is here: https://usehooks.com/useEventListener/
export function useEventListener(eventName, handler, element = window){
  // Create a ref that stores handler
  const savedHandler = useRef();
  
  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On 
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;
      
      // Create event listener that calls handler function stored in ref
      const eventListener = event => savedHandler.current(event);
      
      // Add event listener
      element.addEventListener(eventName, eventListener);
      
      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
};

export function screenResizeHandler(){
  var w = window,
  d = document,
  e = d.documentElement,
  g = d.getElementsByTagName('body')[0],
  x = w.innerWidth || e.clientWidth || g.clientWidth,
  y = w.innerHeight || e.clientHeight || g.clientHeight;
  return({ width: x, height: y });
}