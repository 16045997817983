import React from 'react';

export function HomeIcon(props) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 140 140" {...props}>
      <defs>
        <linearGradient
          id="prefix__a"
          x1={70}
          y1={24.17}
          x2={70}
          y2={100.95}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f7992e" />
          <stop offset={1} stopColor="#e976ad" />
        </linearGradient>
      </defs>
      <path
        fill="url(#prefix__a)"
        d="M70 26.41l-40 31.8v45.38h29.44V71.31h21.12v32.28H110V58.21l-40-31.8z"
      />
    </svg>
  )
}

export function AssessmentTitleIcon(props) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 90 90" {...props}>
      <defs>
        <linearGradient
          id="prefix__b"
          x1={45}
          y1={77.95}
          x2={45}
          y2={6.15}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#e976ad" />
          <stop offset={1} stopColor="#f7992e" />
        </linearGradient>
      </defs>
      <path
        d="M62.14 13.29h-6.59a10.56 10.56 0 00-21.1 0h-6.59a10.58 10.58 0 00-10.57 10.57v44.48a10.59 10.59 0 0010.57 10.58h34.28a10.59 10.59 0 0010.57-10.58V23.86a10.58 10.58 0 00-10.57-10.57zM45 9.64a4 4 0 11-4 4 4 4 0 014-4zm21.15 58.7a4 4 0 01-4 4H27.86a4 4 0 01-4-4V23.86a4 4 0 014-4h1.83v2.56a5.83 5.83 0 005.83 5.83h19a5.83 5.83 0 005.83-5.83v-2.57h1.83a4 4 0 014 4zm-10.33-33L41 50.17l-6.85-6.85A3.28 3.28 0 0029.54 48l8.65 8.66a4 4 0 005.68 0L60.46 40a3.28 3.28 0 00-4.64-4.64z"
        fill="url(#prefix__b)"
      />
    </svg>
  )
}

export function ExecuteNavIcon(props) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 140 140" {...props}>
      <path
        d="M87.81 65.12A48.4 48.4 0 0070 16.92a48.39 48.39 0 00-18.84 38.35 49.12 49.12 0 001 9.85A23.56 23.56 0 0039.76 97a57.89 57.89 0 0124.91-8.2A48.72 48.72 0 0070 93.63a49.55 49.55 0 005.32-4.82A58 58 0 01100.24 97a23.55 23.55 0 00-12.43-31.88zM70 61.45a8.65 8.65 0 118.64-8.64A8.64 8.64 0 0170 61.45zm0 35.63a4.5 4.5 0 00-4.5 4.5v19a4.5 4.5 0 009 0v-19a4.5 4.5 0 00-4.5-4.5zm12.08 0a4.51 4.51 0 00-4.5 4.5v9.56a4.5 4.5 0 009 0v-9.56a4.5 4.5 0 00-4.5-4.5zm-24.16 0a4.5 4.5 0 00-4.5 4.5v9.56a4.5 4.5 0 009 0v-9.56a4.51 4.51 0 00-4.5-4.5z"
        fill="#979797"
      />
    </svg>
  )
}

export function AssessNavIcon(props) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 140 140" {...props}>
      <path
        d="M93.5 26h-9a14.48 14.48 0 00-28.94 0h-9A14.51 14.51 0 0032 40.5v61A14.51 14.51 0 0046.5 116h47a14.51 14.51 0 0014.5-14.5v-61A14.51 14.51 0 0093.5 26zM70 21a5.5 5.5 0 11-5.5 5.5A5.51 5.51 0 0170 21zm29 80.5a5.51 5.51 0 01-5.5 5.5h-47a5.51 5.51 0 01-5.5-5.5v-61a5.51 5.51 0 015.5-5.5H49v3.5a8 8 0 008 8h26a8 8 0 008-8V35h2.5a5.51 5.51 0 015.5 5.5zM84.84 56.29L64.56 76.58l-9.4-9.4a4.5 4.5 0 00-6.37 6.36l11.88 11.88a5.5 5.5 0 007.78 0l22.76-22.76a4.5 4.5 0 10-6.37-6.37z"
        fill="#979797"
      />
    </svg>
  )
}

export function PlanNavIcon(props) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 140 140" {...props}>
      <path
        d="M32 74.48a4.5 4.5 0 006.37 0L44 68.84l5.64 5.64A4.5 4.5 0 0056 68.11l-5.63-5.63L56 56.84a4.5 4.5 0 10-6.36-6.36L44 56.11l-5.63-5.63A4.5 4.5 0 0032 56.84l5.64 5.64L32 68.11a4.52 4.52 0 000 6.37zm71.79-25l5.63-5.64a4.5 4.5 0 10-6.36-6.36l-5.64 5.63-5.63-5.63a4.5 4.5 0 00-6.37 6.36l5.64 5.64-5.64 5.63a4.52 4.52 0 000 6.37 4.53 4.53 0 006.37 0l5.63-5.64 5.64 5.64a4.5 4.5 0 106.36-6.37zm1.13 35a4.49 4.49 0 00-6.36 0l-5.64 5.63-5.64-5.63a4.5 4.5 0 10-6.36 6.36l5.64 5.64-5.64 5.63a4.5 4.5 0 006.36 6.37l5.64-5.64 5.64 5.64a4.5 4.5 0 106.36-6.37l-5.64-5.63 5.64-5.64a4.49 4.49 0 000-6.29zm-24-53.8a4.49 4.49 0 00-3-5.58l-18-5.29-5.29 18a4.5 4.5 0 003 5.59 4.64 4.64 0 001.27.18 4.49 4.49 0 004.31-3.23l1.11-3.76C74.59 57 71.64 74.29 55.36 89.4a16.9 16.9 0 105.86 6.84C70.28 87.9 76 78.74 78.24 69c2.56-11.2.6-23.27-5.79-36l2.88.85a4.49 4.49 0 005.58-3.1zM46 111.12a7.78 7.78 0 117.78-7.78 7.79 7.79 0 01-7.78 7.78z"
        fill="#979797"
      />
    </svg>
  )
}

export function BackIconAlt(props) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 140 140" {...props}>
      <defs>
        <linearGradient
          id="prefix__c"
          x1={70}
          y1={116.88}
          x2={70}
          y2={24.32}
          gradientUnits="userSpaceOnUse"
        >
        <stop offset="0" stopColor="#f7992e"/>
        <stop offset="1" stopColor="#e976ad"/>
        </linearGradient>
      </defs>
      <path
        d="M70 20a50 50 0 1050 50 50 50 0 00-50-50zm21.25 54.5H60.47L73 86.79a4.5 4.5 0 11-6.3 6.42L47.76 74.64a6.52 6.52 0 010-9.28L66.7 46.79a4.5 4.5 0 016.3 6.42L60.47 65.5h30.78a4.5 4.5 0 010 9z"
        fill="url(#prefix__c)"
      />
    </svg>
  )
}

export function BackIcon(props) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 140 140" {...props}>
      <defs>
        <linearGradient
          id="prefix__c"
          x1={70}
          y1={116.88}
          x2={70}
          y2={24.32}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#4896d1" />
          <stop offset={1} stopColor="#32c3f2" />
        </linearGradient>
      </defs>
      <path
        d="M70 20a50 50 0 1050 50 50 50 0 00-50-50zm21.25 54.5H60.47L73 86.79a4.5 4.5 0 11-6.3 6.42L47.76 74.64a6.52 6.52 0 010-9.28L66.7 46.79a4.5 4.5 0 016.3 6.42L60.47 65.5h30.78a4.5 4.5 0 010 9z"
        fill="url(#prefix__c)"
      />
    </svg>
  )
}

export function NextIcon(props) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 140 140" {...props}>
      <defs>
        <linearGradient
          id="prefix__d"
          x1={-4278.5}
          y1={-8149.17}
          x2={-4278.5}
          y2={-8056.62}
          gradientTransform="rotate(180 -2104.25 -4016.145)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#b3b2b3" />
          <stop offset={1} stopColor="#cbcbca" />
        </linearGradient>
      </defs>
      <path
        d="M70 120a50 50 0 10-50-50 50 50 0 0050 50zM48.75 65.5h30.78L67 53.21a4.5 4.5 0 116.3-6.42l18.94 18.57a6.52 6.52 0 010 9.28L73.3 93.21a4.5 4.5 0 11-6.3-6.42L79.53 74.5H48.75a4.5 4.5 0 010-9z"
        fill="url(#prefix__d)"
      />
    </svg>
  )
}