import React, { useEffect, useState, useRef} from 'react';
import { navigate } from 'hookrouter';
import { Button, Image } from 'react-bootstrap';
//import { enterApp, exitApp, useUrlParams } from './common.js';
//import Introduction from './Introduction/Introduction';
//import Assessment from './Assessment/Assessment';
//import  homeMugShot from './images/home-mugshot.png';
//import Compass from '../../components/Compass/Compass';
import { useUrlParams } from '../../../app_suite/common_old';
//import { CCButton, CCButtonCircle } from '../../../components/CompassComponents/CompassComponents';
import { AssessmentTitleIcon, HomeIcon, AssessNavIcon, PlanNavIcon, ExecuteNavIcon, BackIcon, BackIconAlt } from '../../../svg/svg_icons.js';
import {useSpring, animated} from 'react-spring'
import firebase from 'firebase';
import '../../../firebase-init.js'
import './Assessment.css';

let db = firebase.firestore();

export default function Assessment(props) {
  const pageRef = useRef(null);
  // const refContent = useRef(null);
  // let [userAccount, setUserAccount] = useState(null);
  // let [compassPosition, setCompassPosition] = useState(0);

  const thisApp = 'assess';
  let pathSegment = useUrlParams();
  console.log('pathSegment: ', pathSegment);
  console.log('props.show: ', props.show);
  let [prev, setPrev] = useState('');
  let [render, setRender] = useState(false);
  let [animate, setAnimate] = useState(false);
  let [buttonDisplay, setButtonDisplay] = useState('none');

  //let [posFromTop, setPosFromTop] = useState(300);

  useEffect(()=> {
    console.log('INSIDE ASSESSMENT: ');
    if( props.show === thisApp && prev != thisApp  ){
      //enterApp(pageRef, setRender, props.iconRef);
      setRender(true);
      setAnimate(true);
      setPrev(thisApp);
    }
    if( props.show != thisApp && prev === thisApp ){
      //exitApp(pageRef, setRender, props.iconRef);
      setAnimate(false);
      setTimeout(()=> { setRender(false) }, 600);
      setPrev(props.show);
    }
  });

  console.log('render: ', render);
  const springProps = useSpring({
    config: {
      tension: 250,
      friction: 25,
    },
    marginTop: animate ? '0vh' : '100vh',
    onRest: ()=> setButtonDisplay('block')
  })
//

  const buttonsAppear = {
    display: buttonDisplay
  }
  return(
    <React.Fragment>
    {render ?
      <div>
        <animated.div style={springProps} className="Assess-wrapper">
          <div ref={pageRef} >
            <div onClick={()=> { setButtonDisplay('none'); window.history.go(-1) } } style={{position: 'absolute', top: 30, left: 30}}>

            </div>
            <div style={{marginTop: 150, backgroundColor: 'white', width: '100%'}}>
              <div className="Assess-description-block" style={{height: 120}}>
                <div className="Assess-heading">
                <AssessmentTitleIcon className="Assess-heading-icon" />
                   <p>Assessment</p>
                </div>
                {/*<p>All assessments are completely optional. The information collected 
                will be for your benefit only. If you would prefer to skip this 
    step you can go straight to 2. Plan.</p>*/}
              </div>
            </div>
            {/*<div className="Assess-button-wrapper" style={buttonsAppear}>
              <CCButton>take self assessment</CCButton>
              <CCButton>request peer assessment</CCButton>
              <CCButton>request supervisor assessment</CCButton>
    </div>*/}
          </div>
        </animated.div>
      </div>
      : ''
    }
      
    </React.Fragment>
  )
}