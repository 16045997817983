import React, { useState, useEffect } from 'react';
//import { createContainer } from "unstated-next";
import { Container, Spinner } from 'react-bootstrap';
import firebase from 'firebase';
import Signin from './components/Signin/Signin';
import CreateProfile from './components/CreateProfile/CreateProfile';
import Home from './pages/Home/Home';
//import './firebase-init.js'
import './App.css';

export const useAuth = () => {
  const [state, setState] = useState(() => { 
  let user = firebase.auth().currentUser;
  /*if(user){
    if(user.displayName) {user.registered = true}
  else {user.registered = false}
  }*/
  return { initializing: !user, user, } });
  
  function onChange(user) {
    setState({ initializing: false, user });
  }

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange); // listen for auth state changes
    //console.log('auth state changed ', state);
    return () => unsubscribe(); // unsubscribe to the listener when unmounting
  }, []); // An empty array means this useEffect hook is only run one time.
          // Only once is needed to set up the onAuthStateChanged listener.
  return state;
}

// const Session = createContainer(useAuth);

// export function UserProfile() {
//   console.log('inside UserProfile')
//   let session = Session.useContainer();
//   let user = session.user;
//   console.log('uuuuser: ', user);

//   return(
//   <React.Fragment>
//     {user ? <span >{user.email}</span> : <span>Signed Out</span>}
//   </React.Fragment>
//   );
// }


function App() {
  const { initializing, user } = useAuth();
  if (initializing) {
    return (
      <Container className="App-spinner">
        <Spinner animation="border" variant="info" />
      </Container>
    );
  }else {
    return (
 
    <React.Fragment>
      <div className="App-background" ></div>
        {user ? 
          !user.displayName?
            <div >
              <CreateProfile user={user}/>
            </div>
          :
          <React.Fragment>
            <Home user={user}/>

          </React.Fragment>
        : 
          <React.Fragment>
            <Signin />
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

export default App;

