import React, { useEffect, useState, useRef } from 'react';
import { TweenLite, Sine, Elastic, Power1, Power2 } from 'gsap';
 
export function slideInFromBottom( myRef, render ){
  //console.log('Inside slideInFromBottom = ', parent);
  render(true);
  TweenLite.fromTo(myRef.current, .3, 
    {
      display: 'none',
      transform: 'translateY(100vh)',
      opacity: 1,
      width: '100vw',
      height: '200vh',
    },
    {
      display: 'block',
      transform: 'translateY(0)',
      top: 0,
      left: 0,
      opacity: 1,
      borderRadius: 0,
      ease: Power2.easeOut
      //ease: Elastic.easeOut.config(.3, .4)
    }
  );
} 

export function slideOutToBottom( myRef, render ){
  //console.log('Inside slideOutToBottom = ', parent);
  TweenLite.fromTo(myRef.current, .3, 
    {
      display: 'block',
      transform: 'translateY(0)',
      top: 0,
      left: 0,
      opacity: 1,
      borderRadius: 0,
    },
    {
      display: 'none',
      transform: 'translateY(100vh)',
      opacity: 1,
      width: '100vw',
      height: '100vh',
      ease: Power1.easeIn,
      //ease: Elastic.easeInOut.config(.9, .8)
      onComplete: ()=> render(false)
    },
  );
} 

export const enterApp = ( myRef, render, iconref ) => {
  var rect = {};
  if(iconref){
    rect = iconref.current.getBoundingClientRect();
    TweenLite.to(iconref.current, .3, 
      {
        transform: 'scale(10, 10)', 
        opacity: 0,
        backgroundColor: '#000000',
        filter: 'blur(3px)'
      });
  }
  render(true); // Set render hook variable to true which causes page content to mount on DOM
  TweenLite.fromTo(myRef.current, .2, 
    {
      borderRadius: 180,
      display: 'none',
      transform: 'scale(0, 0)',
      top: (-window.innerHeight / 2) + rect.top + ((rect.bottom - rect.top)/2),
      left: (-window.innerWidth / 2) + rect.left + ((rect.right- rect.left)/2),
      opacity: 0,
      width: '100vw',
      height: '100vh',
      //marginTop: 300,
      borderRadius: 0,
      ease: Sine.easeIn,
      //filter: 'blur(10px)'
    },
    {
      borderRadius: 0,
      display: 'block',
      transform: 'scale(1,1)',
      top: 0,
      left: 0,
      opacity: 1,
      borderRadius: 0,
      ease: Sine.easeIn,
      //filter: 'blur(0)'
    }
  );
}

  
export const exitApp = ( myRef, render, iconref ) => {
  var rect = {};
  if(iconref){
    rect = iconref.current.getBoundingClientRect();
    TweenLite.to(iconref.current, .4, 
    {
      transform: 'scale(1, 1)', opacity: 1,
      ease:Elastic.easeOut.config(3.9,1.2),
      backgroundColor: 'white',
      filter: 'blur(0)',
    });
  }

  TweenLite.fromTo(myRef.current, .15, 
    {
      borderRadius: 0,
      display: 'block',
      transform: 'scale(1,1)',
      top: 0,
      left: 0,
      opacity: 1,
      ease: Sine.easeIn
    },
    {
      borderRadius: 180,
      transform: 'scale(.09, .09)',
      top: (-window.innerHeight / 2) + rect.top + ((rect.bottom - rect.top)/2),
      left: (-window.innerWidth / 2) + rect.left + ((rect.right- rect.left)/2),
      opacity: .2,
      width: '100vw',
      height: '100vh',
      display: 'none',
      borderRadius: 0,
      ease: Sine.easeIn,
      onComplete: ()=> render(false)
    }
  );
}

/******** MY CUSTOM HOOKS *******/

// Returns an array that holds the URL paramaters, after the domain name */
export function useUrlParams(){
  let p = window.location.pathname.split('/');
  return p;
}

// A Hook for adding event listeners. An article about his custom hook is here: https://usehooks.com/useEventListener/
export function useEventListener(eventName, handler, element = window){
  // Create a ref that stores handler
  const savedHandler = useRef();
  
  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On 
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;
      
      // Create event listener that calls handler function stored in ref
      const eventListener = event => savedHandler.current(event);
      
      // Add event listener
      element.addEventListener(eventName, eventListener);
      
      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
};

// A hook for key events
export function useKey(key) {
  // Keep track of key state
  const [pressed, setPressed] = useState(false)

  // Does an event match the key we're watching?
  const match = event => key.toLowerCase() == event.key.toLowerCase()

  // Event handlers
  const onDown = event => {
      if (match(event)) setPressed(true)
  }

  const onUp = event => {
      if (match(event)) setPressed(false)
  }

  // Bind and unbind events
  useEffect(() => {
      window.addEventListener("keydown", onDown)
      window.addEventListener("keyup", onUp)
      return () => {
          window.removeEventListener("keydown", onDown)
          window.removeEventListener("keyup", onUp)
      }
  }, [key])

  return pressed
}


export function screenResizeHandler(){
  var w = window,
  d = document,
  e = d.documentElement,
  g = d.getElementsByTagName('body')[0],
  x = w.innerWidth || e.clientWidth || g.clientWidth,
  y = w.innerHeight || e.clientHeight || g.clientHeight;
  return({ width: x, height: y });
}